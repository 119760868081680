import Link from 'next/link';
import { Box } from '@mui/material';
import React from 'react';

import { PROFILE_PATH } from '@shared/constants';
import TruncatedText from 'src/shared/components/trancated-text';

import { MainBox, Avatar, EmailTypography } from './styles';

type TProps = {
	label: string;
	size?: 'md' | 'xl';
	email?: string;
	endElement?: React.ReactNode;
};

export const UserLabel: React.FC<TProps> = ({ label, size = 'md', email, endElement }) => {
	const isBig = size === 'xl';

	return (
		<MainBox $isBig={isBig} component={Link} href={PROFILE_PATH}>
			<Avatar $isBig={isBig}>{label.slice(0, 1)}</Avatar>

			<Box flexGrow={1}>
				<TruncatedText>{label}</TruncatedText>

				{email && <EmailTypography>{email}</EmailTypography>}
			</Box>

			{endElement}
		</MainBox>
	);
};
