'use client';

import { Box, Typography, Avatar as MuiAvatar, styled, type Theme } from '@mui/material';
import { type OverridableComponent } from '@mui/types';
import { type BoxTypeMap } from '@mui/system';

import TruncatedText from 'src/shared/components/trancated-text';

interface TStyledProps {
	$isBig?: boolean;
}
export const MainBox = styled(Box, {
	shouldForwardProp: (prop) => prop !== '$isBig',
})<TStyledProps>(({ theme, $isBig }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(1.25),
	gap: theme.spacing(1),
	userSelect: 'none',
	textDecoration: 'none',
	...($isBig ? { gap: theme.spacing(1.5) } : {}),
})) as OverridableComponent<BoxTypeMap<TStyledProps, 'div', Theme>>;

export const TitleTypography = styled(TruncatedText)(({ theme }) => ({
	...theme.typography.body1,
	flexGrow: 1,
	color: theme.palette.custom.dark.highest,
}));

export const EmailTypography = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(14),
	fontWeight: 500,
	lineHeight: 1.43,
	letterSpacing: '-0.28px',
	color: theme.palette.custom.component.placeholder,
}));

export const Avatar = styled(MuiAvatar, {
	shouldForwardProp: (prop) => prop !== '$isBig',
})<TStyledProps>(({ theme, $isBig }) => ({
	...theme.typography.userLabel,
	width: theme.spacing($isBig ? 6 : 3),
	height: theme.spacing($isBig ? 6 : 3),
	backgroundColor: theme.palette.custom.userBadge.bg,
	color: theme.palette.custom.userBadge.color,
}));
