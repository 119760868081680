import { forwardRef } from 'react';
import { Link, Typography } from '@mui/material';

import { type TruncatedTextProps } from './types';

const TruncatedText = forwardRef<HTMLAnchorElement, TruncatedTextProps>(
	({ asLink, children, ...props }, ref) => {
		const styles = {
			display: 'inline-block',
			maxWidth: '100%',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		} as const;

		const nativeTooltipText = typeof children === 'string' ? children : undefined;

		if (asLink) {
			return (
				<Link ref={ref} color="inherit" sx={{ ...styles }} {...props} title={nativeTooltipText}>
					{children}
				</Link>
			);
		}

		return (
			<Typography ref={ref} sx={{ ...styles }} {...props} title={nativeTooltipText}>
				{children}
			</Typography>
		);
	},
);

TruncatedText.displayName = 'TruncatedText';

export default TruncatedText;
